<div class="container">
  <div class="loginCard">
      <input 
        [(ngModel)]="email"
        type="email" 
        name="email" 
        class="formInput" />
      <input type="password" 
        [(ngModel)]="password"
        name="password" 
        class="formInput" />
      <button class="btn" 
              id="btn-login" 
              (click)="login()">
              Log In
      </button>
        <button class="btn" 
              id="btn-login" 
              (click)="logout()">
              Log Out
      </button>
  </div>
</div>