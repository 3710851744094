import { Component } from '@angular/core';
import * as _ from 'lodash';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-stage',
  templateUrl: './stage.component.html',
  styleUrls: ['./stage.component.css'],
  host: {
    'class': 'app-stage',
    '[class.animate-out]': 'animateOut'
  }
})
export class StageComponent {
  invalidCode = false;
  page = 'sign-in';
  animateOut = false;

  constructor (
    private router: Router,
    private db: AngularFireDatabase) {
  }

  enterCode(code) {
    console.log("enter coce", code)
    this.db.object('codes').valueChanges()
      .pipe(take(1))
      .subscribe((codes: string[]) => {
        if (_.includes(codes, code)) {
          this.invalidCode = false;
          this.animateOut = true;

          setTimeout(() => {
            this.router.navigate([`stage/${code}`]);
          }, 1000)
        } else {
          this.invalidCode = true;
        }
      });
  }
}
