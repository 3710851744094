import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing',
  template: `
  <router-outlet></router-outlet>
  `,
  styleUrls: ['./landing.component.css'],
  host: {
    '[class.landing]': 'true'
  }
})
export class LandingComponent implements OnInit {
  constructor() { }
  rain;

  ngOnInit() {
    this.generateRain();
  }

  generateRain() {
    this.rain = [];
    for (let i = 0; i < 50; i++) {
      this.rain.push({
        x: `${Math.ceil(Math.random() * 100)}%`,
        delay: `${Math.ceil(Math.random() * 10000) + 300}ms`
      })
    }
  }

}
