<boat-scene></boat-scene>
<div class="program__base">
    <div class="program__main">
        <h1>Program</h1>
        <div class="program__section">
            <p>This performance is dedicated to you.</p>
            <p>The seed of this piece began in the middle of winter, 2016. 
              The world was starting to feel like a little too much. 
              It emerged as a reaction to a perceived saturation in political, physical and emotional violence.  
              I have seen a lot of artists react to this epoch by making work that reflects the brutality of the world 
              - while this response makes sense to me and feels important, 
              I personally struggle to receive these pieces when I feel I'm already maxed out. 
              We gathered together to help make a performance piece that aims to be empowering and restorative 
              - to offer an audience a chance to be soft in a hard world.
            </p>
            <p><em>Good Things To Do</em> began as a performance installation, 
              an act of in-person care through a medium that often feels isolating. 
              But as the pandemic had us looking for intimacy in unexpected ways, 
              the jump to a fully digital iteration of this piece felt strangely natural. 
              It has meant a lot to us to offer an experience that is restorative, 
              that can leave others feeling more resilient and ready to face the world. 
              It's important to stay awake and aware of the world around us. 
              But if we don't use our muscles for recovery and resilience, they will atrophy. 
              They will die.
            </p>
            <p>This performance is dedicated to you, who can survive brutalities that are systemic or circumstantial, 
              catastrophic or ongoing, death by a thousand cuts or one crushing blow. 
              This is for you, who deserves a precious moment with yourself, 
              whose gifts are essential to the world and must be kept available. This is our offering.
            </p>
            <p>This is for you.</p>
            <p style="font-size: 16px"><em>- Christine, Mishelle, Molly, & Sam</em></p>
        </div>

        <h2>The Makers</h2>
        <div class="program__section makers">
          <h4><strong>ABOUT RUMBLE THEATRE</strong></h4>
          <p>
            <strong>Rumble Theatre</strong> produces contemporary plays from the Canadian and world repertoire,
            commissions and produces new Canadian adaptations of outstanding stories, 
            and fosters meaningful interactions between emerging and established artists. 
          <br/><br/>
            This Vancouver-based company is led by a decentralized leadership team, and is staffed by: 
            <strong>Jivesh Parasram</strong> (Artistic Director), <strong>Christie Watson</strong> (Managing Director) and <strong>Jarin Schexnider</strong> (Operations Manager). 
            Rumble is committed to activating cross-sectoral discussion and collaboration, 
            to creating alternative models of presentation and production, 
            and to build solidarity within an artistically driven, disruptive and decolonial practice.
          </p>

          <h4><strong>Mishelle Cuttler</strong><em> – co-creator, sound design + composition</em></h4>
          <p>
            Mishelle is a Vancouver based composer and sound designer whose work is centred around the integration of sound, music, 
            and storytelling. She has an MFA in Musical Theatre Composition from NYU’s Tisch School of the Arts, and a BFA in Acting from UBC.
            <br/><br/>
            Since gathering restrictions began in March of 2020, Mishelle has been fortunate to find multiple opportunities to make music safely 
            - both through digital streaming work, and socially distant outdoor performances when possible. Some recent projects include <em>The Quarantettes</em> 
            - a live and recorded project of poetry set to music and <em>Made in Canada - an album</em>, podcast and digital compendium with music written using 
            the verbatim words of Latinx migrant farm workers. This summer Mishelle will be co-leading the music team for <em>Break Horizons: 
            A Concert Documentary</em> by Kim Harvey as part of the Transform Festival at The Cultch, and designing sound for <em>Done/Undone</em> with Bard on the Beach.
            <a target="_blank" href="https://mishellecuttler.com">mishellecuttler.com</a>.
          </p>

          <h4><strong>Molly MacKinnon</strong><em> – co-creator, sound design + composition</em></h4>
          <p>Molly is a violinist and collaborative artist based on the unceded territories of the Coast Salish people. 
            An interdisciplinary creator and performer with a passion for storytelling through music, 
            Molly has worked with Vancouver New Music, the Vancouver Symphony Orchestra, Little Chamber Music, 
            and many other groups around town. Recent collaborations include <em>The Quarantettes</em>; 
            a roving singing group brought into being during the pandemic, a composition for Vancouver New Music's One Page Score project, 
            and Carousel Theatre's audioplay <em>Rishi & d Douen</em>, produced by Rumble Theatre. Molly is also the co-founder of <em>Concerts On Tap</em>, 
            a series that pairs breweries and classical music.
            <a target="_blank" href="https://mollymackinnon.ca">mollymackinnon.ca</a>.
          </p>

          <h4><strong>Christine Quintana</strong><em> – co-creator, text + concept</em></h4>
          <p>Christine is a playwright, actor, and theatre producer based on the unceded territories of the Musqueam, 
            Squamish, and Tsleil-Waututh peoples. Recent writing/creation projects include: 
            <em>Someone Like You</em> (Silver Commission audio play for Arts Club Theatre Company), 
            Clean (translation and adaptation by Paula Zelaya Cervantes, audio explorations produced by Neworld Theatre and Aluna Theatre), 
            <em>The Fleetwing Fables</em> (audio land walk for Caravan Farm Theatre), and <em>Good Things To Do</em> (immersive digital experience produced by Rumble Theatre). 
            Performance projects include <em>Made In Canada</em> (rice & beans theatre) and co-founding The Quarantettes, a roving singing group delivering neighbourhood tunes at a physical distance. 
            Christine is a graduate of UBC's BFA Acting Program, and a co-founding member of the Canadian Latinx Theatre Artist Coalition.
            <a target="_blank" href="https://christinequintana.ca">christinequintana.ca</a>.
          </p>

          <h4><strong>Sam MacKinnon</strong><em> - collaborator, software design & development, promotional art</em></h4>
          <p>Sam is a web developer, designer, and animator. Pre-covid, he was the co-creater and host of the
            monthly variety show "At Least We Tried" at the Tipper in Vancouver. Recently Sam has worked on developing
            digital theatre solutions for <em>Rumble Theatre</em> (Vancouver) and <em>Theatre Conspiracy</em> (Toronto).
            He is always on the lookout for interesting creative collaborations! <strong>sam.w.mackinnon@gmail.com</strong></p>
        </div>

        <h2>Thank yous</h2>
        <div class="program__section">
            <p>
              We acknowledge and thank Coast Salish people on whose land we created and broadcast this piece 
              - we are grateful to draw inspiration from the traditional and unceded territories of the Musqueam, Squamish and Tsleil-Waututh nations, 
              and stand in solidarity with their continued efforts to protect the land and water we live on.
            </p>

            <p>
              Thank you to our early supporters: Boca del Lupo, who supported this production through their Artist Residency Program, 
              the rEvolver Theatre Festival, and Spiderwebshow’s foldA Festival.
            </p>

            <p>Thank you to Kaitlin Williams and Sasha Singer-Wilson for their contributions to the development of this piece.</p>

        </div>

        <h2>Up Next</h2>
        <div class="program__section">
            <p>
              We are currently in the process of exploring ways to make this piece accessible to d/Deaf, hearing impaired, blind, or low-sighted audience members.
            </p>
            <!-- <p style="font-size: 16px">If you'd like to tell us about your experience with Good Things To Do, please email us at <strong>goodthingsperformance@gmail.com</strong></p> -->
        </div>
    </div>
</div>

<nav class="goodthings-nav">
  <a href="/">Home</a>
  <a href="https://www.imagotheatre.ca/en/good-things-to-do" target="_blank">Tickets</a>
  <a href="/stage">Stage</a>
</nav>
