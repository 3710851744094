import { Component } from '@angular/core';

@Component({
  selector: 'boat-scene',
  template: `
  <rain></rain>
  <div class="water">
    <img class="boat" src="assets/sailboat.png"/>
    <div class="waveHorizontals">
      <div id="waveHorizontal1" class="waveHorizontal"></div>
      <div id="waveHorizontal2" class="waveHorizontal"></div>
      <div id="waveHorizontal3" class="waveHorizontal"></div>
    </div>
    <div class="water-base"></div> 
  `,
  host: {
    'class': 'boat-scene'
  },
  styleUrls: ['./boat-scene.component.css'],
})
export class BoatSceneComponent {

}
