import { Component } from '@angular/core';

@Component({
  selector: 'program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.css'],
  host: {
    'class': 'program'
  }
})
export class ProgramComponent {
}
