import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'drawer',
  template: `
    <div class="drawer-wrapper" [class.close]="closed" [class.hide]="hidden" (click)="close($event)"></div>
    <div class="drawer" [class.open]="!closed">
      <div class="drawer-header">
        <span class="drawer-header__title">{{ title }}</span>
        <button class="drawer-header__close" (click)="beforeClose($event)">×</button>
      </div>
      <div class="drawer-content">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./drawer.component.css']
})
export class DrawerComponent {
  @Input() title: string;
  @Input() actionButtonTitle = 'Update';
  @Input() actionButton = true;
  @Input() closed = true;

  @Output() actionEvent = new EventEmitter<any>();
  @Output() closeEvent = new EventEmitter<any>();

  hidden = true;

  beforeClose(e) {
    this.closeEvent.emit(e);
    this.close();
  }

  close(event = undefined) {
    this.closed = true;
    setTimeout(() => {
      this.hidden = true;
    }, 500)
  }

  open() {
    this.closed = false;
    this.hidden = false;
  }

  fireAction(e) {
    this.actionEvent.emit(e);
    this.close();
  }
}
