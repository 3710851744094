import { Component } from '@angular/core';

@Component({
  selector: 'home',
  template: `
  <boat-scene></boat-scene>
  <div class="home-text">
    <div class="home-content">
      <h1>Good Things To Do</h1>
      <nav class="home-nav">
        <a href="/program">Program</a>
        <a href="https://www.imagotheatre.ca/en/good-things-to-do" target="_blank">Tickets</a>
        <a href="/stage">Stage</a>
      </nav>
    </div>
  </div>
  `,
  styleUrls: ['./home.component.css'],
  host: {
    'class': 'home',
    '[class.animate-in]': 'true'
  }
})
export class HomeComponent {

}
