<boat-scene><</boat-scene>
<form *ngIf="page === 'sign-in'" class="stage-form animate-in">
  <p>
      For the best experience, <br/> please view on a <em>Chrome</em> or <em>Safari</em> browser,<br/>
      and use a computer, not your phone.
  </p>
  <br/><br/>
  <input #codeInput placeholder="Enter Show Code"/>
  <div *ngIf="invalidCode">Sorry! That code is not correct.</div>
  <button (click)="enterCode(codeInput.value)">Submit</button>
</form>

<nav class="goodthings-nav">
  <a href="/">Home</a>
  <a href="/program">Program</a>
  <a href="https://www.imagotheatre.ca/en/good-things-to-do" target="_blank">Tickets</a>
</nav>
